import React, { useState } from 'react';
import supabase from './Supabase';
import './Signup.css';
import { useAuth } from './AuthContext';

interface SignupProps {
  onClose: () => void;
  needsLoginState?: boolean;
}

function Signup({ onClose, needsLoginState }: SignupProps) {
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginState, setLoginState] = React.useState(!!needsLoginState);
  const { signIn } = useAuth();

  const handleSignup = async () => {
    const { error } = await supabase.auth.admin.createUser({
      email,
      password,
      email_confirm: true,
      user_metadata: { first_name: firstName }
    });
    if (error) {
    } else {
      await signIn(email, password);
      onClose();
    }
  };

  const handleGoogleSignup = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        scopes: 'https://www.googleapis.com/auth/userinfo.profile',
      },
    });
    if (error) {
    }
  };

  const openTerms = () => {
    window.open('#/terms-of-service', '_blank');
  };

  const openPrivacyPolicy = () => {
    window.open('#/privacy-policy', '_blank');
  };

  return (
    <div className='signup-container'>
      <div onClick={onClose} className='close-button'>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.238985 10.556C-0.0734518 10.8684 -0.0883298 11.4263 0.246424 11.7536C0.573739 12.0809 1.13166 12.0734 1.4441 11.761L5.99675 7.2013L10.5568 11.761C10.8767 12.0809 11.4272 12.0809 11.7545 11.7536C12.0744 11.4189 12.0818 10.8759 11.7545 10.556L7.20186 5.99628L11.7545 1.44399C12.0818 1.12413 12.0818 0.573694 11.7545 0.246405C11.4198 -0.0734461 10.8767 -0.0808845 10.5568 0.238966L5.99675 4.7987L1.4441 0.238966C1.13166 -0.0734461 0.5663 -0.0883229 0.246424 0.246405C-0.0808908 0.573694 -0.0734518 1.13157 0.238985 1.44399L4.79908 5.99628L0.238985 10.556Z" fill="#121212" />
        </svg>
      </div>
      <div className='signup-header'>{loginState ? 'Log in' : 'Create a profile'}</div>
      {!loginState && <input
        className='signup-input'
        type="text"
        placeholder="Name"
        value={firstName}
        onChange={e => setFirstName(e.target.value)}
      />}
      <input
        className='signup-input'
        type="email"
        placeholder="Email"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <input
        className='signup-input'
        type="password"
        placeholder="Password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <button className='create-account-button' onClick={handleSignup}>{loginState ? 'Log in' : 'Create account'}</button>
      <div className='signup-or-divider'>
        <span>OR</span>
      </div>
      <button className='google-button' onClick={handleGoogleSignup}>
        <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.1011 2.39014L12.5695 7.48748L5.88867 2.39014V2.39151L5.89675 2.39841V9.53603L12.4942 14.7435L19.1011 9.73709V2.39014Z" fill="#EA4335" />
          <path d="M20.8168 1.1476L19.1016 2.38748V9.73446L24.499 5.59055V3.09421C24.499 3.09421 23.8439 -0.471143 20.8168 1.1476Z" fill="#FBBC05" />
          <path d="M19.1016 9.73718V19.2664H23.2384C23.2384 19.2664 24.4156 19.1452 24.5003 17.8033V5.59326L19.1016 9.73718Z" fill="#34A853" />
          <path d="M5.89705 19.2685V9.52982L5.88867 9.52295L5.89705 19.2685Z" fill="#C5221F" />
          <path d="M5.88937 2.38853L4.1835 1.15553C1.15651 -0.463208 0.5 3.10078 0.5 3.10078V5.59703L5.88937 9.52614V2.38853Z" fill="#C5221F" />
          <path d="M5.88867 2.39014V9.52778L5.89705 9.53466V2.39703L5.88867 2.39014Z" fill="#C5221F" />
          <path d="M0.5 5.60107V17.8111C0.583413 19.1544 1.76192 19.2743 1.76192 19.2743H5.89879L5.88937 9.52877L0.5 5.60107Z" fill="#4285F4" />
        </svg>
        <span>Sign in with Google</span>
      </button>
      {!loginState &&
        <div className='signup-disclaimer'>
          By signing in to Yoridachi, you agree to our{' '}
          <span className="signup-disclaimer-link" onClick={openTerms}>
            Terms
          </span>{' '}
          and{' '}
          <span className="signup-disclaimer-link" onClick={openPrivacyPolicy}>
            Privacy Policy
          </span>.
        </div>
      }
      <div className='signup-login-message'>
        {loginState ? `Don't have an account?` : 'Have an account?'}
        <span onClick={() => setLoginState(!loginState)} className='signup-login-button'>
          {loginState ? 'Sign up' : 'Log in'}
        </span>
      </div>
    </div>
  );
}

export default Signup;
