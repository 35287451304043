import './NeedsLogin.css';

interface NeedsLoginProps {
  onSignupClick: () => void;
  onLoginClick: () => void;
  onDismissClick: () => void;
}

const NeedsLogin = ({ onSignupClick, onLoginClick, onDismissClick }: NeedsLoginProps) => {
  return (
    <div className="needs-login">
      <div className="needs-login-dismiss-button" onClick={onDismissClick}>
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.238985 11.1966C-0.0734518 11.5091 -0.0883298 12.0669 0.246424 12.3942C0.573739 12.7215 1.13166 12.7141 1.4441 12.4017L5.99675 7.84193L10.5568 12.4017C10.8767 12.7215 11.4272 12.7215 11.7545 12.3942C12.0744 12.0595 12.0818 11.5165 11.7545 11.1966L7.20186 6.63691L11.7545 2.08461C12.0818 1.76476 12.0818 1.21432 11.7545 0.88703C11.4198 0.567179 10.8767 0.559741 10.5568 0.879591L5.99675 5.43932L1.4441 0.879591C1.13166 0.567179 0.5663 0.552302 0.246424 0.88703C-0.0808908 1.21432 -0.0734518 1.7722 0.238985 2.08461L4.79908 6.63691L0.238985 11.1966Z" fill="#121212" />
        </svg>
      </div>
      <img className="needs-login-dog" src={`${process.env.PUBLIC_URL}/dog.png`} alt="Dog" />
      <div className="needs-login-title">
        Create a profile to unlock more!
      </div>
      <div className="needs-login-subtitle">
        Save recipes, track creations, and explore more delicious possibilities.
      </div>
      <button className="needs-login-signup-button" onClick={onSignupClick}>Create a profile</button>
      <button className="needs-login-login-button" onClick={onLoginClick}>Log in</button>
    </div>
  );
};

export default NeedsLogin;