import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './LandingPage';
import Preferences from './Preferences';
import AdminRecipeList from './AdminRecipeList';
import posthog from 'posthog-js';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import { AuthProvider } from './AuthContext';
import { PreferencesProvider } from './PreferencesContext';

// Initialize PostHog
posthog.init('phc_TNlF0DzQE3keC3qi5Ni3OwV6JEe2PK8XAKiF0ej9jaP', {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'always',
});

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <PreferencesProvider>
          <Router>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/preferences" element={<Preferences />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/admin" element={<AdminRecipeList />} />
            </Routes>
          </Router>
        </PreferencesProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
