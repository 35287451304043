import React, { useState, useEffect, useCallback } from 'react';
import { Tabs, Tab, Drawer, CircularProgress } from '@mui/material';
import { useAuth } from './AuthContext';
import supabase from './Supabase';
import RecipeListItem from './RecipeListItem';
import { Recipe } from './RecipeList';
import './Cookbook.css';
import RecipeDetails from './RecipeDetails';

interface CookbookProps {
  children: React.ReactNode;
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
}

const Cookbook: React.FC<CookbookProps> = ({ children, tabIndex, setTabIndex }) => {
  const [savedRecipes, setSavedRecipes] = useState<Recipe[]>([]);
  const [userRecipes, setUserRecipes] = useState<Recipe[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { user, firstName } = useAuth();
  const [savedPage, setSavedPage] = useState(1);
  const [userPage, setUserPage] = useState(1);
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe | null>(null);

  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 5 && !loading) {
      if (tabIndex === 0) {
        setSavedPage((prev) => prev + 1);
      } else if (tabIndex === 1) {
        setUserPage((prev) => prev + 1);
      }
    }
  }, [tabIndex, loading]);

  useEffect(() => {
    const debounceScroll = () => {
      let timeoutId: NodeJS.Timeout;
      return () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(handleScroll, 200);
      };
    };

    const debouncedHandleScroll = debounceScroll();

    window.addEventListener('scroll', debouncedHandleScroll);
    return () => window.removeEventListener('scroll', debouncedHandleScroll);
  }, [handleScroll]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedRecipe]);

  useEffect(() => {
    if (user && tabIndex === 0) {
      fetchSavedRecipes();
    }
    if (user && tabIndex === 1) {
      fetchUserRecipes();
    }
  }, [user, tabIndex, savedPage, userPage]);

  useEffect(() => {
    if (!selectedRecipe) {
      if (tabIndex === 0) {
        setSavedPage(1);
        fetchSavedRecipes();
      } else if (tabIndex === 1) {
        setUserPage(1);
        fetchUserRecipes();
      }
    }
  }, [selectedRecipe, tabIndex]);

  const fetchSavedRecipes = async () => {
    if (loading) return;

    setLoading(true);
    try {
      const { data: savedData, error: savedError } = await supabase
        .from('saved_recipe')
        .select('recipe_id')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false })
        .range((savedPage - 1) * 20, savedPage * 20 - 1);

      if (savedError) {
        console.error('Error fetching saved recipe IDs:', savedError);
        setError('Failed to load saved recipes. Please try again.');
        setLoading(false);
        return;
      }

      if (savedData && savedData.length > 0) {
        const recipeIds = savedData.map((item: any) => item.recipe_id);

        const { data: recipesData, error: recipesError } = await supabase
          .from('recipes')
          .select('*')
          .in('id', recipeIds);

        if (recipesError) {
          console.error('Error fetching recipes:', recipesError);
          setError('Failed to load saved recipes. Please try again.');
          setLoading(false);
          return;
        }

        if (recipesData) {
          const recipesMap = new Map(recipesData.map((recipe: any) => [recipe.id, recipe]));

          const orderedRecipes = recipeIds
            .map((id) => {
              const recipe = recipesMap.get(id);
              if (recipe) {
                const parsedRecipe = recipe.recipe as Recipe;
                return { ...parsedRecipe, id: recipe.id };
              }
              return null;
            })
            .filter(Boolean);

          setSavedRecipes((prev) => {
            const combinedRecipes = new Map(prev.map((recipe) => [recipe.id, recipe]));
            orderedRecipes.forEach((recipe) => {
              if (recipe && !combinedRecipes.has(recipe.id)) {
                combinedRecipes.set(recipe.id, recipe);
              }
            });
            return Array.from(combinedRecipes.values());
          });
        } else {
          setSavedRecipes([]);
        }
      } else {
        setSavedRecipes([]);
      }
    } catch (err) {
      console.error('Unexpected error fetching saved recipes:', err);
      setError('Failed to load saved recipes. Please try again.');
    }
    setLoading(false);
  };

  const fetchUserRecipes = async () => {
    if (loading) return;

    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('recipes')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false })
        .range((userPage - 1) * 20, userPage * 20 - 1);

      if (error) {
        console.error('Error fetching user recipes:', error);
        setError('Failed to load your recipes. Please try again.');
      } else if (data) {
        const newRecipes = data.map((item: any) => {
          const parsedRecipe = item.recipe as Recipe;
          const formattedDate = new Date(item.created_at).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
          return { ...parsedRecipe, id: item.id, createdAt: formattedDate };
        });
        setUserRecipes((prev) => {
          const uniqueRecipes = new Map(prev.map((recipe) => [recipe.id, recipe]));
          newRecipes.forEach((recipe) => {
            if (!uniqueRecipes.has(recipe.id)) {
              uniqueRecipes.set(recipe.id, recipe);
            }
          });
          return Array.from(uniqueRecipes.values());
        });
      } else {
        setUserRecipes([]);
      }
    } catch (err) {
      console.error('Unexpected error fetching user recipes:', err);
      setError('Failed to load your recipes. Please try again.');
    }
    setLoading(false);
  };

  const handleRecipeUnsaved = (recipeId: number) => {
    setSavedRecipes((prev) => prev.filter((recipe) => recipe.id !== recipeId));
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  if (selectedRecipe) {
    return (
      <RecipeDetails
        recipe={selectedRecipe}
        onClose={(isSaved: boolean) => {
          if (tabIndex === 0) {
            if (!isSaved) {
              handleRecipeUnsaved(selectedRecipe.id);
            }
          }
          setSelectedRecipe(null);
        }}
        setError={setError}
        setSignup={() => {}}
      />
    );
  }

  return (
    <div className='cookbook-container'>
      <div className='cookbook-content'>
        <div className='cookbook-header'>
          {!firstName && <span>Your recipes</span>}
          {firstName && <span>{`Your recipes, ${firstName}`}</span>}
        </div>
        <Tabs
          className='cookbook-tabs'
          variant='fullWidth'
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="cookbook tabs"
          TabIndicatorProps={{ style: { backgroundColor: "#b84f28" } }}
          sx={{
            "& .MuiTab-root": {
              color: "#121212",
            },
            "& .Mui-selected": {
              color: "#b84f28 !important",
              fontWeight: "700 !important",
            },
          }}
        >
          <Tab label="SAVED" />
          <Tab label="HISTORY" />
        </Tabs>

        {tabIndex === 0 && (
          <div className='saved-recipes'>
            {savedRecipes.map((recipe) => (
              <RecipeListItem
                key={recipe.id}
                recipe={recipe}
                hideIngredients={true}
                showCreatedDate={false}
                onRecipeClick={() => setSelectedRecipe(recipe)}
              />
            ))}
          </div>
        )}

        {tabIndex === 1 && (
          <div className='user-recipes'>
            {userRecipes.map((recipe) => (
              <RecipeListItem
                key={recipe.id}
                recipe={recipe}
                hideIngredients={true}
                showCreatedDate={true}
                onRecipeClick={() => setSelectedRecipe(recipe)}
              />
            ))}
          </div>
        )}

        {loading && (
          <div className='loading-spinner'>
            <CircularProgress />
          </div>
        )}

        {error && <div className='cookbook-error'>{error}</div>}
      </div>
      {children}
    </div>
  );
};

export default Cookbook;
