import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className='privacy-policy'>
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> September 1st, 2024</p>

      <h2>1. Introduction</h2>
      <p>This Privacy Policy explains how Yoridachi ("we," "our," or "us") collects, uses, and protects your personal information when you use our service.</p>

      <h2>2. Information We Collect</h2>
      <ul>
        <li><strong>Personal Information:</strong> When you use our service, we may collect personal information such as your name, email address, and any other details you provide.</li>
        <li><strong>Usage Data:</strong> We may collect information about how you use our service, including your IP address, browser type, and operating system.</li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <ul>
        <li><strong>To Provide and Improve Services:</strong> We use your information to generate recipes, improve our service, and customize your experience.</li>
        <li><strong>Communication:</strong> We may use your contact information to send you updates, newsletters, and promotional materials, if you have opted in to receive them.</li>
      </ul>

      <h2>4. Data Sharing and Disclosure</h2>
      <ul>
        <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our service.</li>
        <li><strong>Legal Requirements:</strong> We may disclose your information if required by law or to protect our rights and safety.</li>
      </ul>

      <h2>5. Data Security</h2>
      <p>We implement reasonable security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure.</p>

      <h2>6. Your Choices</h2>
      <ul>
        <li><strong>Access and Correction:</strong> You may request access to or correction of your personal information by contacting us.</li>
        <li><strong>Opt-Out:</strong> You can opt-out of receiving promotional communications from us by following the unsubscribe instructions in those communications.</li>
      </ul>

      <h2>7. Children's Privacy</h2>
      <p>Our service is not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13.</p>

      <h2>8. Changes to Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the new Privacy Policy on our website. Your continued use of the service after any changes constitutes acceptance of the new Privacy Policy.</p>

      <h2>9. Contact Us</h2>
      <p>If you have any questions about these Terms or our Privacy Policy, please contact us at:</p>
      <p>info.yoridachi@gmail.com</p>
    </div>
  );
};

export default PrivacyPolicy;
