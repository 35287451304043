import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Drawer } from '@mui/material';
import { useAuth } from './AuthContext';
import supabase from './Supabase';
import NeedsLogin from './NeedsLogin';
import './RecipeDetails.css';
import { Recipe } from './RecipeList';

interface RecipeDetailsProps {
  recipe: Recipe;
  onClose: (isRecipeSaved: boolean) => void;
  setError: React.Dispatch<React.SetStateAction<string>>;
  setSignup: React.Dispatch<React.SetStateAction<number>>;
}

const RecipeDetails: React.FC<RecipeDetailsProps> = ({ recipe, onClose, setError, setSignup }) => {
  const [recipeTabIndex, setRecipeTabIndex] = useState(0);
  const [isRecipeSaved, setIsRecipeSaved] = useState(false);
  const [needsLoginModal, setNeedsLoginModal] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (user && recipe) {
      checkIfRecipeIsSaved();
    }
  }, [user, recipe]);

  const checkIfRecipeIsSaved = async () => {
    try {
      const { data, error } = await supabase
        .from('saved_recipe')
        .select('*')
        .eq('user_id', user.id)
        .eq('recipe_id', recipe.id)
        .single();

      if (error && error.code !== 'PGRST116') {
        console.error('Error checking if recipe is saved:', error);
      } else {
        setIsRecipeSaved(!!data);
      }
    } catch (err) {
      console.error('Unexpected error:', err);
    }
  };

  const handleSaveOrUnsaveRecipe = async () => {
    if (!user) {
      setNeedsLoginModal(true);
      return;
    }

    try {
      if (isRecipeSaved) {
        const { error } = await supabase
          .from('saved_recipe')
          .delete()
          .eq('user_id', user.id)
          .eq('recipe_id', recipe.id);

        if (error) {
          console.error('Error unsaving recipe:', error);
          setError('Failed to unsave the recipe. Please try again.');
        } else {
          setIsRecipeSaved(false);
        }
      } else {
        const { error } = await supabase
          .from('saved_recipe')
          .insert({
            user_id: user.id,
            recipe_id: recipe.id,
          });

        if (error) {
          console.error('Error saving recipe:', error);
          setError('Failed to save the recipe. Please try again.');
        } else {
          setIsRecipeSaved(true);
        }
      }
    } catch (err) {
      console.error('Unexpected error:', err);
      setError('Failed to save the recipe. Please try again.');
    }
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setRecipeTabIndex(newValue);
  };

  const needsLoginDrawer = (
    <Drawer
      anchor={'bottom'}
      open={needsLoginModal}
      onClose={() => setNeedsLoginModal(false)}
    >
      <NeedsLogin
        onSignupClick={() => { setNeedsLoginModal(false); setSignup(1); }}
        onLoginClick={() => { setNeedsLoginModal(false); setSignup(2); }}
        onDismissClick={() => setNeedsLoginModal(false)}
      />
    </Drawer>
  );

  return (
    <div className='recipe-container'>
      <div className='recipe-title'>
        {recipe.title}
      </div>
      <div className='recipe-description'>
        {recipe.longHealthyDescription}
      </div>

      <Tabs
        className='recipe-tabs'
        variant='fullWidth'
        TabIndicatorProps={{
          style: {
            backgroundColor: "#b84f28"
          }
        }}
        sx={{
          "& .MuiTab-root": {
            color: "#121212",
            minHeight: "80px",
            height: "80px",
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: "500 !important",
          },
          "& .Mui-selected": {
            color: "#b84f28 !important",
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: "700 !important",
          },
        }}
        value={recipeTabIndex}
        onChange={handleTabChange}
        aria-label="recipe tabs"
      >
        <Tab label="INGREDIENTS" />
        <Tab label="INSTRUCTIONS" />
      </Tabs>

      {recipeTabIndex === 0 && (
        <>
          <ul className='recipe-ingredient-list'>
            {recipe.fullIngredients.map((ingredient, index) => (
              <li key={index} className='recipe-ingredient-item'>
                <div className='recipe-ingredient-details'>
                  <div className='recipe-ingredient-name'>{ingredient.name}</div>
                  <div className='recipe-ingredient-detail'>{ingredient.detail}</div>
                </div>
                <div className='recipe-ingredient-quantity'>
                  {ingredient.amount}
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
      {recipeTabIndex === 1 && (
        <ul className='recipe-instruction-list'>
          {recipe.instructions.map((instruction, index) => (
            <div className='recipe-instruction-container'>
              <span key={index} className='recipe-instruction-number'>{`${index + 1}.`}</span>
              <span key={index} className='recipe-instruction-text'>{`${instruction}`}</span>
            </div>
          ))}
        </ul>
      )}
      <div className='recipe-ingredient-disclaimer-title'>Always follow food safety practices.</div>
      <div className='recipe-ingredient-disclaimer-description'>
        This recipe is crafted with the magic of AI while following the guidelines reviewed by a dietitian.
        For more information, you can view the Recommended Food Safety Guidelines.
      </div>
      <div className='recipe-close-button-container'>
        <button className='recipe-close-button' onClick={() => onClose(isRecipeSaved)}>
          Close
        </button>
      </div>
      {!isRecipeSaved &&
        <button className='recipe-save-button' onClick={handleSaveOrUnsaveRecipe}>
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.23077 1.43286C9.23077 0.752087 8.68077 0.202087 8 0.202087C7.31923 0.202087 6.76923 0.752087 6.76923 1.43286V6.97132H1.23077C0.55 6.97132 0 7.52132 0 8.20209C0 8.88286 0.55 9.43286 1.23077 9.43286H6.76923V14.9713C6.76923 15.6521 7.31923 16.2021 8 16.2021C8.68077 16.2021 9.23077 15.6521 9.23077 14.9713V9.43286H14.7692C15.45 9.43286 16 8.88286 16 8.20209C16 7.52132 15.45 6.97132 14.7692 6.97132H9.23077V1.43286Z" fill="white" />
          </svg>
          Save Recipe
        </button>
      }
      {isRecipeSaved &&
        <button className='recipe-unsave-button' onClick={handleSaveOrUnsaveRecipe}>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 15.9998C24 16.6806 23.45 17.2306 22.7692 17.2306H9.23077C8.55 17.2306 8 16.6806 8 15.9998C8 15.319 8.55 14.769 9.23077 14.769H22.7692C23.45 14.769 24 15.319 24 15.9998Z" fill="#121212" />
          </svg>
          Unsave Recipe
        </button>
      }
      {needsLoginDrawer}
    </div>
  );
};

export default RecipeDetails;
