import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, List, ListItem, ListItemText, Typography, Tabs, Tab, CircularProgress, TextField } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { createSearchParams, useNavigate } from 'react-router-dom';

interface Recipe {
  id: number;
  title: string;
  quickHealthyDescription: string;
  longHealthyDescription: string;
  keyIngredients: string[];
  fullIngredients: { name: string; amount: string; detail: string }[];
  instructions: string[];
}

const defaultSystemPrompt = `You will always generate a list of three recipes in VALID JSON.
The three recipes must be in a JSON array.
Each recipe must have an id, title, quickHealthyDescription, nutrition facts table, longHealthyDescription, keyIngredients array, fullIngredients array (where each ingredient is a map of name, amount (with units eg. 1 tbsp), and detail), and instructions array(do not put a number in front of the instruction).
You are a world class chef who has written countless cookbooks. When a user asks you for a set of recipes ensure that they are unique, dietician approved, high quality, easy to understand, have accurate ingredients, have accurate instructions, and will leave them satisfied to have made the recipe.
If the user specifies they have any of the following medical conditions please take care to tailor the recipes to accommodate.

Type 2 diabetes condition:
15-30g of carbohydrates including added sugars per meal
and/or
5-10g of added sugars per meal
and/or
5-10g of fibre per meal
and/or
include low-moderate glycemic index foods

At the end of each quickHealthyDescription, include a 'Powered by Yoridachi™'.`;

const fetchRecipes = async (
  recipes: Recipe[],
  setRecipes: React.Dispatch<React.SetStateAction<Recipe[]>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  systemPrompt: string
) => {
  setLoading(true);
  setError('');

  // Fetch preferences from local storage
  const servingSize = localStorage.getItem('servingSize');
  const priorityItems = JSON.parse(localStorage.getItem('priorityItems') || '[]');
  const durationAvailability = localStorage.getItem('durationAvailability');
  const avoidItems = JSON.parse(localStorage.getItem('avoidItems') || '[]');
  const healthConditions = JSON.parse(localStorage.getItem('healthConditions') || '[]');
  const model = localStorage.getItem('model') || 'llama-3.1-70b-versatile';

  const query = `Generate recipes based on the following preferences:
    Serving Size: ${servingSize},
    Priority Items: ${priorityItems.join(', ')},
    Duration Availability: ${durationAvailability},
    Avoid Items: ${avoidItems.join(', ')},
    Health Conditions: ${healthConditions.join(', ')}
    Differentiate from these existing recipes: ${recipes.map((recipe) => recipe.title).join(', ')}
  `;

  const requestBody = {
    model: model,
    query: query,
    system: systemPrompt,
  };

  console.log('Request Body:', JSON.stringify(requestBody, null, 2)); // Debugging log

  try {
    const response = await fetch('https://us-east1-yoridachi.cloudfunctions.net/generateRecipes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      const errorText = await response.text(); // Read the response text
      throw new Error(`Failed to fetch recipes: ${errorText}`);
    }

    const data = await response.json();
    const newRecipes = JSON.parse(data.choices[0].message.content).recipes;
    setRecipes((prevRecipes) => [...prevRecipes, ...newRecipes]);

  } catch (error) {
    console.error('Error fetching recipes:', error);
    setError('Error fetching recipes. Please try again.');
  } finally {
    setLoading(false);
  }
};

const AdminRecipeList: React.FC = () => {
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe | null>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [systemPrompt, setSystemPrompt] = useState(localStorage.getItem('systemPrompt') || defaultSystemPrompt);
  const hasFetched = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasFetched.current) {
      fetchRecipes(recipes, setRecipes, setLoading, setError, systemPrompt);
      hasFetched.current = true;
    }
  }, [recipes, systemPrompt]);

  useEffect(() => {
    localStorage.setItem('systemPrompt', systemPrompt);
  }, [systemPrompt]);

  const handleRecipeClick = (recipe: Recipe) => {
    setSelectedRecipe(recipe);
  };

  const handleGenerateMore = async () => {
    fetchRecipes(recipes, setRecipes, setLoading, setError, systemPrompt);
  };

  const handleClearRecipes = async () => {
    setRecipes([]);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleSystemPromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSystemPrompt(event.target.value);
  };

  if (selectedRecipe) {
    return (
      <Box sx={{ padding: 2, maxWidth: 800, margin: '0 auto' }}>
        <Button variant="contained" color="primary" onClick={() => setSelectedRecipe(null)} sx={{ marginBottom: 2 }}>
          Back to Recipes
        </Button>
        <Typography variant="h5" component="h2" gutterBottom>
          {selectedRecipe.title}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {selectedRecipe.longHealthyDescription}
        </Typography>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="recipe tabs">
          <Tab label="Ingredients" />
          <Tab label="Instructions" />
        </Tabs>
        <Box sx={{ marginTop: 2 }}>
          {tabIndex === 0 && (
            <List>
              {selectedRecipe.fullIngredients.map((ingredient, index) => (
                <ListItem key={index} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <ListItemText
                    primary={ingredient.name}
                    secondary={ingredient.detail}
                  />
                  <Typography variant="body2" color="textSecondary">
                    {ingredient.amount}
                  </Typography>
                </ListItem>
              ))}
            </List>
          )}
          {tabIndex === 1 && (
            <List>
              {selectedRecipe.instructions.map((instruction, index) => (
                <ListItem key={index}>
                  <ListItemText primary={`${index + 1}. ${instruction}`} />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', padding: 2, maxWidth: 1600, margin: '0 auto' }}>
      <Box sx={{ flex: 1, marginRight: 2 }}>
        <Typography variant="h6" component="h3" gutterBottom>
          System Prompt
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={35}
          value={systemPrompt}
          onChange={handleSystemPromptChange}
          placeholder="Enter system prompt here"
          variant="outlined"
        />
        <Button variant="contained" color="primary" fullWidth onClick={handleClearRecipes} sx={{ marginTop: 2 }}>
          Clear Existing Recipes
        </Button>
      </Box>
      <Box sx={{ flex: 2 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Recipes for you
        </Typography>

        {error && (
          <Typography variant="body2" color="error" gutterBottom>
            {error}
          </Typography>
        )}

        <List>
          {recipes.map((recipe) => (
            <ListItem key={recipe.id} button onClick={() => handleRecipeClick(recipe)}>
              <ListItemText
                primary={recipe.title}
                secondary={
                  <>
                    <Typography variant="body2" color="textSecondary">
                      {recipe.quickHealthyDescription}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {recipe.keyIngredients.slice(0, 2).map((ingredient, index) => (
                        <span key={index}>{(index > 0 ? ', ' : '') + ingredient}</span>
                      ))}
                      {recipe.keyIngredients.length > 2 && `, + ${recipe.keyIngredients.length - 2} more`}
                    </Typography>
                  </>
                }
              />
              <ArrowForward />
            </ListItem>
          ))}
        </List>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
            <CircularProgress />
          </Box>
        )}

        {!loading && (
          <>
            <Button variant="contained" color="primary" fullWidth onClick={handleGenerateMore} sx={{ marginTop: 2 }}>
              Generate More
            </Button>
          </>
        )}
        <Button variant="contained" color="secondary" fullWidth onClick={() => navigate({pathname: '/preferences', search: createSearchParams({admin: 'true'}).toString()})} sx={{ marginTop: 2 }}>
            Edit Preferences
        </Button>
      </Box>
    </Box>
  );
};

export default AdminRecipeList;
