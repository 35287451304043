import { Recipe } from "./RecipeList";

interface RecipeListItemProps {
  recipe: Recipe;
  recipeIndex?: number;
  hideIngredients?: boolean;
  showCreatedDate?: boolean;
  onRecipeClick: (recipe: Recipe) => void;
}

const RecipeListItem: React.FC<RecipeListItemProps> = ({ recipe, recipeIndex, hideIngredients, showCreatedDate, onRecipeClick }) => {
  return (
    <div className='recipe-list-recipe' key={recipe.id} onClick={() => onRecipeClick(recipe)}>
      <div className='recipe-list-recipe-title-container'>
        <div className='recipe-list-recipe-title'>
          {recipeIndex !== undefined ? `${recipeIndex + 1}. ${recipe.title}` : recipe.title}
        </div>
        <svg
          className='recipe-list-recipe-icon'
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9.23607 8.80687C9.68235 8.36059 9.68235 7.63584 9.23607 7.18956L2.38122 0.33471C1.93494 -0.111569 1.21019 -0.111568 0.763908 0.33471C0.317629 0.78099 0.317629 1.50575 0.763908 1.95203L6.81188 8L0.767478 14.048C0.321199 14.4943 0.321199 15.219 0.767478 15.6653C1.21376 16.1116 1.93851 16.1116 2.38479 15.6653L9.23964 8.81044L9.23607 8.80687Z" fill="black" />
        </svg>
      </div>
      <div className='recipe-list-recipe-description'>{recipe.quickHealthyDescription}</div>
      {showCreatedDate && recipe.createdAt && <div className='recipe-list-recipe-created-date'>Created on: {recipe.createdAt}</div>}
      {!hideIngredients &&
        <div className='recipe-list-recipe-footer'>
          {recipe.keyIngredients.slice(0, 2).map((ingredient, index) => (
            <span key={index}>+ {ingredient}</span>
          ))}
          {recipe.fullIngredients.length > 2 && <span>+ {recipe.fullIngredients.length - 2} more</span>}
        </div>
      }
    </div>
  );
};

export default RecipeListItem;
