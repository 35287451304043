import React from 'react';
import './TermsOfService.css';

const TermsOfService: React.FC = () => {
  return (
    <div className='terms-of-service'>
      <h1>Terms of Service</h1>
      <p><strong>Effective Date:</strong> September 1st, 2024</p>

      <h2>1. Acceptance of Terms</h2>
      <p>By accessing or using Yoridachi ("we," "our," or "us"), you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use our service.</p>

      <h2>2. Service Description</h2>
      <p>Yoridachi is an AI-powered recipe generator that allows users to input ingredients, dietary preferences, and serving sizes to receive personalized recipe suggestions.</p>

      <h2>3. User Responsibilities</h2>
      <ul>
        <li><strong>Accuracy:</strong> You agree to provide accurate and complete information when using our service.</li>
        <li><strong>Compliance:</strong> You agree to use the service in compliance with all applicable laws and regulations.</li>
      </ul>

      <h2>4. Intellectual Property</h2>
      <p>All content and materials provided through Yoridachi, including but not limited to text, images, and software, are owned by us or our licensors and are protected by intellectual property laws.</p>

      <h2>5. Limitation of Liability</h2>
      <p>We are not liable for any damages arising from your use of our service, including but not limited to direct, indirect, incidental, punitive, or consequential damages.</p>

      <h2>6. Changes to Terms</h2>
      <p>We may update these Terms from time to time. We will notify you of any material changes by posting the new Terms on our website. Your continued use of the service after any changes constitutes acceptance of the new Terms.</p>

      <h2>7. Termination</h2>
      <p>We may terminate or suspend your access to our service at any time, with or without cause, and with or without notice.</p>

      <h2>8. Governing Law</h2>
      <p>These Terms are governed by and construed in accordance with the laws of Canada, without regard to its conflict of law principles.</p>
    </div>
  );
};

export default TermsOfService;
