import './Loading.css';

interface LoadingProps {
  onCancel: () => void;
}

const Loading = ({ onCancel }: LoadingProps) => {
  return (
    <div className='loading-container'>
      <div className='loading-content'>
        <div className='loading-header'>Make something great</div>
        <img className="loading-animation" src={`${process.env.PUBLIC_URL}/loading-animation.gif`} alt="Loading animation" />
      </div>
      <div className="loading-buttons">
        <button className="loading-cancel-button" onClick={onCancel}>Cancel</button>
        <div className="loading-status">
          <svg className='loading-spinner' width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35 19C35 21.1011 34.5861 23.1817 33.7821 25.1229C32.978 27.0641 31.7994 28.828 30.3137 30.3137C28.828 31.7994 27.0641 32.978 25.1229 33.7821C23.1817 34.5861 21.1011 35 19 35C16.8988 35 14.8183 34.5861 12.8771 33.7821C10.9359 32.978 9.17203 31.7994 7.68629 30.3137C6.20055 28.828 5.022 27.0641 4.21793 25.1229C3.41385 23.1817 3 21.1011 3 19C3 16.8988 3.41385 14.8183 4.21793 12.8771C5.022 10.9359 6.20055 9.17203 7.68629 7.68629C9.17203 6.20055 10.9359 5.022 12.8771 4.21793C14.8183 3.41385 16.8989 3 19 3C21.1012 3 23.1817 3.41385 25.1229 4.21793C27.0641 5.022 28.828 6.20056 30.3137 7.68629C31.7994 9.17203 32.978 10.9359 33.7821 12.8771C34.5861 14.8183 35 16.8989 35 19L35 19Z" stroke="#B84F28" strokeWidth="5.33333" />
            <path d="M35 19C35 21.525 34.4024 24.0141 33.2561 26.2638C32.1098 28.5136 30.4473 30.4601 28.4046 31.9443C26.3618 33.4284 23.9968 34.408 21.5029 34.803C19.0091 35.198 16.4571 34.9972 14.0557 34.2169" stroke="white" strokeWidth="5.33333" strokeLinecap="round" />
          </svg>
          Generating recipes...
        </div>
      </div>
    </div>
  );
}

export default Loading;