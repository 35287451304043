import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import './Preferences.css';
import { Preferences, usePreferences } from './PreferencesContext';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface PreferencesPaneProps {
  handleSave?: (newPreferences: Preferences) => void;
  handleCancel?: () => void;
}

const PreferencesPane: React.FC<PreferencesPaneProps> = ({ handleSave, handleCancel }) => {
  const { preferences, setPreferences } = usePreferences();
  const [servingSize, setServingSize] = useState<number>(1);
  const [priorityItems, setPriorityItems] = useState<string[]>([]);
  const [avoidItems, setAvoidItems] = useState<string[]>([]);
  const [healthConditions, setHealthConditions] = useState<string[]>([]);
  const [durationAvailability, setDurationAvailability] = useState<string>('30 minutes or less');
  const [newInput, setNewInput] = useState<{ type: string; value: string | null }>({ type: '', value: null });

  // Refs for managing focus
  const inputRef = useRef<HTMLInputElement | null>(null);

  const navigate = useNavigate();
  const query = useQuery();
  const isAdmin = query.get('admin');

  useEffect(() => {
    if (preferences) {
      setServingSize(preferences.serving_size ? Number(preferences.serving_size.split(' ')[0]) : 1);
      setPriorityItems(preferences.priority_items || []);
      setDurationAvailability(preferences.duration_availability || '30 minutes or less');
      setAvoidItems(preferences.avoid_items || []);
      setHealthConditions(preferences.health_conditions || []);
    }
  }, [preferences]);

  useEffect(() => {
    if (newInput.value !== null && inputRef.current) {
      inputRef.current.focus();
    }
  }, [newInput]);

  const savePreferencesToContext = async () => {
    const updatedPreferences = {
      serving_size: servingSize === 1 ? '1 serving' : `${servingSize} servings`,
      priority_items: priorityItems,
      duration_availability: durationAvailability,
      avoid_items: avoidItems,
      health_conditions: healthConditions,
    };

    await setPreferences(updatedPreferences);
    return updatedPreferences;
  };

  const handleSaveDefault = () => {
    if (isAdmin) {
      navigate('/admin');
    } else {
      navigate('/recipes?g=1');
    }
  };

  const incrementServingSize = () => {
    if (servingSize < 10) {
      setServingSize(servingSize + 1);
    }
  };

  const decrementServingSize = () => {
    if (servingSize > 1) {
      setServingSize(servingSize - 1);
    }
  };

  const handleAddInput = (type: string) => {
    setNewInput({ type, value: '' });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, listType: string) => {
    const newList = [...(listType === 'priority' ? priorityItems : listType === 'avoid' ? avoidItems : healthConditions)];
    newList[index] = event.target.value;
    if (listType === 'priority') setPriorityItems(newList);
    else if (listType === 'avoid') setAvoidItems(newList);
    else if (listType === 'health') setHealthConditions(newList);
  };

  const handleRemoveItem = (setList: React.Dispatch<React.SetStateAction<string[]>>, list: string[], index: number) => {
    const updatedList = list.filter((_, i) => i !== index);
    setList(updatedList);
  };

  const handleAddItem = (type: string, value: string) => {
    if (!value.trim()) return;
    if (type === 'priority') setPriorityItems([...priorityItems, value.trim()]);
    else if (type === 'avoid') setAvoidItems([...avoidItems, value.trim()]);
    else if (type === 'health') setHealthConditions([...healthConditions, value.trim()]);

    setNewInput({ type: '', value: null });
  };

  return (
    <div className='preferences-container'>
      <div className='header'>Your Preferences</div>
      <img className="preferences-tips-image" src={`${process.env.PUBLIC_URL}/preferences-tips.png`} alt="Preference Tips" />

      <div className='serving-size-container'>
        <div className='ingredient-header'>
          Serving size
        </div>
        <div className='serving-size-control-container'>
          <button className='serving-size-button' onClick={decrementServingSize}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24 16.0001C24 16.6808 23.45 17.2308 22.7692 17.2308H9.23077C8.55 17.2308 8 16.6808 8 16.0001C8 15.3193 8.55 14.7693 9.23077 14.7693H22.7692C23.45 14.7693 24 15.3193 24 16.0001Z" fill="black" />
            </svg>
          </button>
          <div className='serving-size-display'>{servingSize}</div>
          <button className='serving-size-button' onClick={incrementServingSize}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.2308 9.23077C17.2308 8.55 16.6808 8 16 8C15.3192 8 14.7692 8.55 14.7692 9.23077V14.7692H9.23077C8.55 14.7692 8 15.3192 8 16C8 16.6808 8.55 17.2308 9.23077 17.2308H14.7692V22.7692C14.7692 23.45 15.3192 24 16 24C16.6808 24 17.2308 23.45 17.2308 22.7692V17.2308H22.7692C23.45 17.2308 24 16.6808 24 16C24 15.3192 23.45 14.7692 22.7692 14.7692H17.2308V9.23077Z" fill="#121212" />
            </svg>
          </button>
        </div>
      </div>

      <div className='items-container'>
        <div className='ingredient-header'>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 20.616L20.944 23.6L19.632 17.976L24 14.192L18.248 13.696L16 8.40002L13.752 13.696L8 14.192L12.36 17.976L11.056 23.6L16 20.616Z" fill="#121212" />
          </svg>
          Priority ingredients
        </div>
        <ul className='item-list'>
          {priorityItems.map((item, index) => (
            <li className='item-container' key={index}>
              <input
                className='item-input'
                value={item}
                onChange={(e) => handleInputChange(e, index, 'priority')}
              />
              <button className='item-close-button' onClick={() => handleRemoveItem(setPriorityItems, priorityItems, index)}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.238985 10.556C-0.0734518 10.8684 -0.0883298 11.4263 0.246424 11.7536C0.573739 12.0809 1.13166 12.0734 1.4441 11.761L5.99675 7.2013L10.5568 11.761C10.8767 12.0809 11.4272 12.0809 11.7545 11.7536C12.0744 11.4189 12.0818 10.8759 11.7545 10.556L7.20186 5.99628L11.7545 1.44399C12.0818 1.12413 12.0818 0.573694 11.7545 0.246405C11.4198 -0.0734461 10.8767 -0.0808845 10.5568 0.238966L5.99675 4.7987L1.4441 0.238966C1.13166 -0.0734461 0.5663 -0.0883229 0.246424 0.246405C-0.0808908 0.573694 -0.0734518 1.13157 0.238985 1.44399L4.79908 5.99628L0.238985 10.556Z" fill="#121212" />
                </svg>
              </button>
            </li>
          ))}
          {newInput.type === 'priority' && (
            <li className='item-container'>
              <input
                ref={inputRef}
                className='item-input'
                value={newInput.value || ''}
                onChange={(e) => setNewInput({ ...newInput, value: e.target.value })}
                onBlur={() => handleAddItem('priority', newInput.value || '')}
              />
              <button className='item-close-button' onClick={() => setNewInput({ type: '', value: null })}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.238985 10.556C-0.0734518 10.8684 -0.0883298 11.4263 0.246424 11.7536C0.573739 12.0809 1.13166 12.0734 1.4441 11.761L5.99675 7.2013L10.5568 11.761C10.8767 12.0809 11.4272 12.0809 11.7545 11.7536C12.0744 11.4189 12.0818 10.8759 11.7545 10.556L7.20186 5.99628L11.7545 1.44399C12.0818 1.12413 12.0818 0.573694 11.7545 0.246405C11.4198 -0.0734461 10.8767 -0.0808845 10.5568 0.238966L5.99675 4.7987L1.4441 0.238966C1.13166 -0.0734461 0.5663 -0.0883229 0.246424 0.246405C-0.0808908 0.573694 -0.0734518 1.13157 0.238985 1.44399L4.79908 5.99628L0.238985 10.556Z" fill="#121212" />
                </svg>
              </button>
            </li>
          )}
        </ul>
        <button className='item-submit-button' onClick={() => handleAddInput('priority')}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.23077 1.23077C9.23077 0.55 8.68077 0 8 0C7.31923 0 6.76923 0.55 6.76923 1.23077V6.76923H1.23077C0.55 6.76923 0 7.31923 0 8C0 8.68077 0.55 9.23077 1.23077 9.23077H6.76923V14.7692C6.76923 15.45 7.31923 16 8 16C8.68077 16 9.23077 15.45 9.23077 14.7692V9.23077H14.7692C15.45 9.23077 16 8.68077 16 8C16 7.31923 15.45 6.76923 14.7692 6.76923H9.23077V1.23077Z" fill="#121212" />
          </svg>
          Add an ingredient
        </button>
      </div>

      <div className="items-container">
        <div className="ingredient-header">Cooking time</div>
        <select
          className="cooking-time-dropdown"
          value={durationAvailability}
          onChange={(e) => setDurationAvailability(e.target.value)}
        >
          <option value="30 minutes or less">30 minutes or less</option>
          <option value="30 minutes to an hour">30 minutes to an hour</option>
          <option value="1 hour to 1.5 hours">1 hour to 1.5 hours</option>
          <option value="More than 2 hours">More than 2 hours</option>
        </select>
      </div>

      <div className='items-container'>
        <div className='ingredient-header'>Avoid ingredients</div>
        <ul className='item-list'>
          {avoidItems.map((item, index) => (
            <li className='item-container' key={index}>
              <input
                className='item-input'
                value={item}
                onChange={(e) => handleInputChange(e, index, 'avoid')}
              />
              <button className='item-close-button' onClick={() => handleRemoveItem(setAvoidItems, avoidItems, index)}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.238985 10.556C-0.0734518 10.8684 -0.0883298 11.4263 0.246424 11.7536C0.573739 12.0809 1.13166 12.0734 1.4441 11.761L5.99675 7.2013L10.5568 11.761C10.8767 12.0809 11.4272 12.0809 11.7545 11.7536C12.0744 11.4189 12.0818 10.8759 11.7545 10.556L7.20186 5.99628L11.7545 1.44399C12.0818 1.12413 12.0818 0.573694 11.7545 0.246405C11.4198 -0.0734461 10.8767 -0.0808845 10.5568 0.238966L5.99675 4.7987L1.4441 0.238966C1.13166 -0.0734461 0.5663 -0.0883229 0.246424 0.246405C-0.0808908 0.573694 -0.0734518 1.13157 0.238985 1.44399L4.79908 5.99628L0.238985 10.556Z" fill="#121212" />
                </svg>
              </button>
            </li>
          ))}
          {newInput.type === 'avoid' && (
            <li className='item-container'>
              <input
                ref={inputRef}
                className='item-input'
                value={newInput.value || ''}
                onChange={(e) => setNewInput({ ...newInput, value: e.target.value })}
                onBlur={() => handleAddItem('avoid', newInput.value || '')}
              />
              <button className='item-close-button' onClick={() => setNewInput({ type: '', value: null })}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.238985 10.556C-0.0734518 10.8684 -0.0883298 11.4263 0.246424 11.7536C0.573739 12.0809 1.13166 12.0734 1.4441 11.761L5.99675 7.2013L10.5568 11.761C10.8767 12.0809 11.4272 12.0809 11.7545 11.7536C12.0744 11.4189 12.0818 10.8759 11.7545 10.556L7.20186 5.99628L11.7545 1.44399C12.0818 1.12413 12.0818 0.573694 11.7545 0.246405C11.4198 -0.0734461 10.8767 -0.0808845 10.5568 0.238966L5.99675 4.7987L1.4441 0.238966C1.13166 -0.0734461 0.5663 -0.0883229 0.246424 0.246405C-0.0808908 0.573694 -0.0734518 1.13157 0.238985 1.44399L4.79908 5.99628L0.238985 10.556Z" fill="#121212" />
                </svg>
              </button>
            </li>
          )}
        </ul>
        <button className='item-submit-button' onClick={() => handleAddInput('avoid')}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.23077 1.23077C9.23077 0.55 8.68077 0 8 0C7.31923 0 6.76923 0.55 6.76923 1.23077V6.76923H1.23077C0.55 6.76923 0 7.31923 0 8C0 8.68077 0.55 9.23077 1.23077 9.23077H6.76923V14.7692C6.76923 15.45 7.31923 16 8 16C8.68077 16 9.23077 15.45 9.23077 14.7692V9.23077H14.7692C15.45 9.23077 16 8.68077 16 8C16 7.31923 15.45 6.76923 14.7692 6.76923H9.23077V1.23077Z" fill="#121212" />
          </svg>
          Add an ingredient
        </button>
      </div>

      <div className='items-container'>
        <div className='ingredient-header'>Health conditions</div>
        <ul className='item-list'>
          {healthConditions.map((item, index) => (
            <li className='item-container' key={index}>
              <input
                className='item-input'
                value={item}
                onChange={(e) => handleInputChange(e, index, 'health')}
              />
              <button className='item-close-button' onClick={() => handleRemoveItem(setHealthConditions, healthConditions, index)}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.238985 10.556C-0.0734518 10.8684 -0.0883298 11.4263 0.246424 11.7536C0.573739 12.0809 1.13166 12.0734 1.4441 11.761L5.99675 7.2013L10.5568 11.761C10.8767 12.0809 11.4272 12.0809 11.7545 11.7536C12.0744 11.4189 12.0818 10.8759 11.7545 10.556L7.20186 5.99628L11.7545 1.44399C12.0818 1.12413 12.0818 0.573694 11.7545 0.246405C11.4198 -0.0734461 10.8767 -0.0808845 10.5568 0.238966L5.99675 4.7987L1.4441 0.238966C1.13166 -0.0734461 0.5663 -0.0883229 0.246424 0.246405C-0.0808908 0.573694 -0.0734518 1.13157 0.238985 1.44399L4.79908 5.99628L0.238985 10.556Z" fill="#121212" />
                </svg>
              </button>
            </li>
          ))}
          {newInput.type === 'health' && (
            <li className='item-container'>
              <input
                ref={inputRef}
                className='item-input'
                value={newInput.value || ''}
                onChange={(e) => setNewInput({ ...newInput, value: e.target.value })}
                onBlur={() => handleAddItem('health', newInput.value || '')}
              />
              <button className='item-close-button' onClick={() => setNewInput({ type: '', value: null })}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.238985 10.556C-0.0734518 10.8684 -0.0883298 11.4263 0.246424 11.7536C0.573739 12.0809 1.13166 12.0734 1.4441 11.761L5.99675 7.2013L10.5568 11.761C10.8767 12.0809 11.4272 12.0809 11.7545 11.7536C12.0744 11.4189 12.0818 10.8759 11.7545 10.556L7.20186 5.99628L11.7545 1.44399C12.0818 1.12413 12.0818 0.573694 11.7545 0.246405C11.4198 -0.0734461 10.8767 -0.0808845 10.5568 0.238966L5.99675 4.7987L1.4441 0.238966C1.13166 -0.0734461 0.5663 -0.0883229 0.246424 0.246405C-0.0808908 0.573694 -0.0734518 1.13157 0.238985 1.44399L4.79908 5.99628L0.238985 10.556Z" fill="#121212" />
                </svg>
              </button>
            </li>
          )}
        </ul>
        <button className='item-submit-button' onClick={() => handleAddInput('health')}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.23077 1.23077C9.23077 0.55 8.68077 0 8 0C7.31923 0 6.76923 0.55 6.76923 1.23077V6.76923H1.23077C0.55 6.76923 0 7.31923 0 8C0 8.68077 0.55 9.23077 1.23077 9.23077H6.76923V14.7692C6.76923 15.45 7.31923 16 8 16C8.68077 16 9.23077 15.45 9.23077 14.7692V9.23077H14.7692C15.45 9.23077 16 8.68077 16 8C16 7.31923 15.45 6.76923 14.7692 6.76923H9.23077V1.23077Z" fill="#121212" />
          </svg>
          Add a health condition
        </button>
      </div>

      <div className="preference-buttons">
        <button className="preferences-cancel-button" onClick={() => {
          if (handleCancel) {
            handleCancel();
          } else if (isAdmin) {
            navigate('/admin');
          } else {
            navigate('/recipes');
          }
        }}>Cancel</button>
        <button className="preferences-generate-button" onClick={async () => {
          const newPreferences = await savePreferencesToContext();
          if (handleSave) {
            handleSave(newPreferences);
          } else {
            handleSaveDefault();
          }
        }}>Generate recipe</button>
      </div>
    </div>
  );
};

export default PreferencesPane;
