import { sign } from 'crypto';
import { useAuth } from './AuthContext';
import './Profile.css';

import React from 'react';

interface ProfileProps {
  children: React.ReactNode;
}

const Profile = ({ children }: ProfileProps) => {
  const { user, signOut, firstName } = useAuth();
  const joinDate = user.created_at && new Date(user.created_at).toLocaleString('en-US', { month: 'long', year: 'numeric', timeZone: 'UTC' });

  return (
    <div className="profile-container">
      <div className='profile-content'>
        <img className="profile-header-image" src={`${process.env.PUBLIC_URL}/profile-header.png`} alt="Header" />
        {firstName && <div className="profile-user-name">{firstName}</div>}
        {joinDate && <div className='profile-join-date'>{`Joined ${joinDate}`}</div>}
        <div className='profile-support-header'>SUPPORT</div>
        <a href="mailto:info.yoridachi@gmail.com" className="profile-contact-us-button">
          Contact us
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.2361 16.8069C20.6823 16.3606 20.6823 15.6358 20.2361 15.1896L13.3812 8.33471C12.9349 7.88843 12.2102 7.88843 11.7639 8.33471C11.3176 8.78099 11.3176 9.50575 11.7639 9.95203L17.8119 16L11.7675 22.048C11.3212 22.4943 11.3212 23.219 11.7675 23.6653C12.2138 24.1116 12.9385 24.1116 13.3848 23.6653L20.2396 16.8104L20.2361 16.8069Z" fill="#424242" />
          </svg>
        </a>
        <button className="profile-sign-out-button" onClick={signOut}>Sign Out</button>
      </div>
      {children}
    </div>
  );
};

export default Profile;
